<ion-header>
	<ion-toolbar color="primary">
		<ion-title>{{ product.name }}</ion-title>
		<ion-buttons slot="start">
			<ion-button (click)="closeModal()"
				><ion-icon slot="icon-only" name="arrow-back" aria-label="Back"></ion-icon
			></ion-button>
		</ion-buttons>
		<ion-buttons slot="end">
			<ion-button (click)="closeModal()"
				><ion-icon slot="icon-only" name="close-circle" aria-label="Close"></ion-icon
			></ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-content>
	<ion-grid class="ion-no-padding">
		<ion-row>
			<ion-col size="12" size-lg *ngIf="!!product.imageWide || product.additionalInfo.description">
				<ion-card>
					<ion-grid class="ion-no-padding">
						<ion-row>
							<ion-col *ngIf="!!product.imageWide" size="12" size-sm>
								<picture>
									<source srcset="{{ env.api }}/images/{{ product.imageWide }}.webp" />
									<img
										class="product-image"
										src="{{ env.api }}/images/{{ product.imageWide }}.png"
										alt="Product Image"
										#image
										(error)="image.hidden = true"
										[hidden]="image.hidden"
										width="100%"
										height="100%"
									/>
								</picture>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col size="12" size-sm *ngIf="product.additionalInfo.description">
								<ion-card-content>
									<div [innerHTML]="productHTML"></div>
								</ion-card-content>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-card>
			</ion-col>
			<ion-col size="12" size-lg>
				<div *ngFor="let prepMessage of line.prepMessages">
					<app-prep-message-single-select
						*ngIf="prepMessage.minimumNumOfCalls === 1 && prepMessage.maximumNumOfCalls === 1; else multiselect"
						[prepMessage]="prepMessage"
						(prepChange)="onPrepSelectionChanged($event)"
					></app-prep-message-single-select>
					<ng-template #multiselect
						><app-prep-message-multi-select
							[prepMessage]="prepMessage"
							[product]="product"
							(prepChange)="onPrepSelectionChanged($event)"
						></app-prep-message-multi-select
					></ng-template>
				</div>
				<ion-card *ngIf="!disableProductNotes">
					<ion-card-header>
						<ion-card-subtitle>Notes</ion-card-subtitle>
						<ion-item>
							<ion-textarea placeholder="Enter more information here..." [(ngModel)]="line.notes"></ion-textarea>
						</ion-item>
					</ion-card-header>
				</ion-card>
			</ion-col>
		</ion-row>
	</ion-grid>
	<ion-toolbar class="global-footer" color="primary">
		<ion-title *ngIf="!line.id" (click)="submit()" class="ion-text-center">ADD TO ORDER</ion-title>
		<ion-title *ngIf="!!line.id" (click)="submit()" class="ion-text-center">UPDATE ITEM</ion-title>
		<ion-buttons slot="start" class="ion-no-padding">
			<ion-button slot="start" (click)="decrease()" aria-label="Decrease">
				<ion-icon slot="icon-only" name="remove-circle"></ion-icon>
			</ion-button>
			<ion-label class="footer-qty">{{ line.qty }}</ion-label>
			<ion-button (click)="increase()" aria-label="Increase">
				<ion-icon slot="icon-only" name="add-circle"></ion-icon>
			</ion-button>
		</ion-buttons>
		<ion-label slot="end" class="footer-total">{{ calculatedPrice | currencyAmount }}</ion-label>
	</ion-toolbar>
</ion-content>
